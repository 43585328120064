<template>
    <v-row no-gutters>
        <v-col cols="12">
          <v-toolbar dark color="primary pl-2 pr-2" dense>
            <v-toolbar-title v-if="invPaymentDtl.type=='EXPENSE'">Expenses Payment History Detail - </v-toolbar-title>
            <v-toolbar-title v-if="invPaymentDtl.type=='INVOICE'">Invoice Received History Detail - </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="$emit('closeDialog')">
              <v-icon>cancel</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>
            <v-col cols="12" md="12" class="pl-1 pt-1 pb-1">
          <v-simple-table
            :fixed-header="true"
            class="elevation-1 align-center"
            height="90vh"
            dense
          >
            <template v-slot:default>
              <thead>
                <tr >   
                  <th class="text-center white--text primary">Date</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Payment Term</th>                                    
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Invoice No.</th>                
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Note</th>                  
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Note Remark</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Remark</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Type Name</th>                  
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <!-- <th class="text-center white--text primary">Sign</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th> -->
                  <th class="text-center white--text primary">Payment Amt</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Ex Rate</th>
                  <th class="text-center white--text primary">
                    <v-divider vertical></v-divider>
                  </th>
                  <th class="text-center white--text primary">Received</th>
                 <th class="text-center white--text primary">
                   <v-divider vertical></v-divider>
                 </th>
                 <th class="text-center white--text primary">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in invPaymentList"
                :key="index"
                @click="selectedOne = item"
                :style="{
                  backgroundColor:
                    item.invPaymentId == selectedOne.invPaymentId
                      ? '#def3ff'
                      : 'transparent',
                }"
                >
                  <td class="text-center">{{item.date}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{item.paymentTerm}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{item.invoiceCode}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td>{{item.note}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td>{{item.noteRemark}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td>{{item.remark}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-center">{{item.typeName}}</td>
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <!-- <td>{{item.symbols}}</td>    
                  <td class="text-center"><v-divider vertical></v-divider></td> -->
                  <td>{{item.payment|numberFormat}}</td>                   
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-right">{{item.exchangeRate|numberFormat}}</td>                  
                  <td class="text-center"><v-divider vertical></v-divider></td>
                  <td class="text-right">{{item.paymentMMK|numberFormat}}({{item.symbols}})</td>
                  <td class="text-center"><v-divider vertical></v-divider></td> 
                   <td class="text-center">
                  <v-btn small icon color="blue" @click="clickInvoicePrint(item)">
                     <v-icon>print</v-icon></v-btn
                   >
                   <v-btn small icon color="primary" @click="clickEdit(item)">
                     <v-icon>edit</v-icon></v-btn
                   >
                 </td>          
                </tr>
                <v-divider />
              </tbody>
            </template>
          </v-simple-table>          
        </v-col>
        <v-col>
      <v-dialog
      v-model="dialogSave"
      width="700"
    >
      <v-card>
        <v-card-title class="text-h5 white--text primary">
          Invoice Payment for ({{ipNo}} ,balance {{invPaymentDtl.balance+cash}}) 
        </v-card-title>
        <v-card-text class="text-h6 mt-5">
          <v-row no-gutters>
            <v-col cols="12" md="4">
              <v-autocomplete
                      v-model="exchangeDto"
                      item-text="symbols"
                      :items="exchangeList"
                      label="Currency Sign"
                      name="symbols"
                      return-object
                      required
                      dense
                      outlined
                      filled
                    >
                    </v-autocomplete>
            </v-col>
            <v-col cols="12" md="8" class="pl-2">
              <v-autocomplete
                    :rules="[(v) => !!v || 'required']"
                    v-model="cashtype"
                    item-text="typeName"
                    :items="cashtypeList"
                    label="Cash Type"
                    name="typeName"
                    return-object
                    required
                    dense
                    outlined
                    filled
                  >
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="noteDto"
                      item-text="note"
                      :items="noteList"
                      label="note"
                      name="note"
                      return-object
                      required
                      dense
                      outlined
                      filled
                    >
                    </v-autocomplete>
                  </v-col>
              <v-col cols="12" md="6" class="pl-2">
                <v-text-field v-model="noteremark" label="Remark"  filled outlined dense></v-text-field>
              </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model.number="cash" label="Cash"   type="number" filled outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model.number="exRate" label="Exchange Rate" class="pl-2"  type="number" filled outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model.number="bal" label="Balance"   type="number" filled outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="paymentRemark" label="Remark" class="pl-2" filled outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialogSave = false"
          >
            CANCEL
          </v-btn>
          <v-btn
          dark
            color="primary"
            text
            @click="clickSaveDialog()"
          >
            {{saveorupdate}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-col>
    </v-row>    
    </template>
    <script>
   import invoiceService from "../service/InvoiceService";
   import billService from "../service/BillService";
   import exchangeService from "../service/ExchangeService";
   import noteService from "../service/NoteService";
    export default {
      data: () => ({    
        selectedOne: {},    
        invPayment: {},
        invPaymentList:[],
        invoiceDto:{},
        dialogSave:false,
        cash:0,
        bal:0,
    exRate:1,
        invPaymentDto:{},
        cashtypeList: [],
        cashtype: {},
        paymentRemark:"",
        saveorupdate:"SAVE",
        editAmt:0,
        iscash:false,
        exchangeList:[],
        exchangeDto:{},
        noteList:[],
    noteDto:{},
    noteremark:"",
    ipNo:"",
      }),
      props: {invPaymentDtl:Object},
      mounted: function() {
        this.getCashListMethod();
        this.exchangeListMethod();
        this.invPaymentHistoryListMethod();
        this.noteListMethod();
      },
      methods: { 
        noteListMethod: function () {
      noteService
        .getNoteList()
        .then((response) => {
          this.noteList.splice(0, this.noteList.length);
          this.noteList.push(...response);
          this.noteDto = this.noteList[0];
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    },
        clickInvoicePrint:function(item){
              if(item.paymentTerm=="Invoice Payment"){
                let invoiceId = item.invoiceId;
                let invPId=item.invPaymentId;
          let query = {invoiceId,invPId};
            this.$router.push({
              name: "invpayprint",
              query,
            }) .catch(() => {});
              }else{
                let invoiceId = item.invoiceId;
                let invPId=item.invPaymentId;
          let query = {invoiceId,invPId};
            this.$router.push({
              name: "invpayprint",
              query,
            }) .catch(() => {});
              }
        } ,
        clickSaveDialog:function(){
          if(this.saveorupdate=="UPDATE"){
            if(this.cash>0 && this.cash<=(this.invoiceDto.balance+this.editAmt)){
              this.invPaymentList.splice(0);
          this.invoiceDto.advancePaid=this.invoiceDto.advancePaid;
          this.invoiceDto.balance=this.invoiceDto.balance-this.cash+this.editAmt;
          this.invPaymentDto.typeId=this.cashtype.typeId;
          this.invPaymentDto.payment=this.cash;
          this.invPaymentDto.exchangeRate=this.exRate;
          this.invPaymentDto.paymentMMK=this.bal;
          this.invPaymentDto.symbols=this.exchangeDto.symbols;
          this.invPaymentDto.remark=this.paymentRemark;
          this.invPaymentDto.note=this.noteDto.note;
          this.invPaymentDto.noteRemark=this.noteremark;
          this.invPaymentDto.remark=this.paymentRemark;
          this.invPaymentList.push(this.invPaymentDto);
          this.invoiceDto.invPayment=this.invPaymentList;
          invoiceService
            .updateInvoicePayment(this.invoiceDto)
            .then(() => {
            this.dialogSave=false;
            this.invPaymentHistoryListMethod();
            this.noteListMethod();
            this.exchangeListMethod();
            this.cash = 0;
            this.paymentRemark = "";
            this.$swal({
                title: "Successful",
                text: "Save,Success!",
                icon: "success",
                timer: 500,
              });
            })
              .catch((error) => {
              this.$swal("Fail!", error.response.data.message, "error");
            }) ;
        }
        else{
            this.$swal("Warning!", "Cash amount should be greater than 0 or less than or equal the remaining bal!", "warning");
        }
      } 
    },
      clickEdit:function(item){
        console.log(this.invPaymentDtl);
        this.saveorupdate="UPDATE";
        if(item.paymentTerm=="Invoice" || item.paymentTerm=='Expenses'){
          let invoiceId = item.invoiceId;
          let query = {invoiceId};
            this.$router.push({
              name: "invoice",
              query,
            });
        }else{ 
          let tempType = {};
        tempType.typeId = item.typeId;
        tempType.typeName = item.typeName;
        this.cashtype = Object.assign({},tempType);  
        this.dialogSave=true;
        this.editAmt=item.payment;
        this.paymentRemark=item.remark;
        this.noteDto.note=item.note;
        this.noteremark=item.noteRemark;
        this.cash=item.payment;             
        this.exRate=item.exchangeRate;
        this.ipNo=item.invoiceCode;
        this.invPaymentDto=item;
        let exSymbol={};
        exSymbol.symbols=item.symbols;
        exSymbol.exchangeId=item.ipExId;
        this.exchangeDto=Object.assign({},exSymbol);  
        }     
      },
      clickInvoiceEdit:function(item){
        let invoiceId = item.invoiceId;
          let query = {invoiceId};
            this.$router.push({
              name: "invoice",
              query,
            });
      },       
      exchangeListMethod: function () {
      exchangeService
        .getExchange()
        .then((response) => {
          this.exchangeList.splice(0, this.exchangeList.length);
          this.exchangeList.push(...response);
          this.exchangeDto = this.exchangeList[0];
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });
    }, 
      invPaymentHistoryListMethod: function () {
        invoiceService
        .getInvPaymentById(this.invPaymentDtl.invoiceId)
        .then((response) => {
          this.invoiceDto=response;
          this.invPaymentList=this.invoiceDto.invPayment;
        })
        .catch((error) => {
          this.$swal("Fail!", error.response.data.message, "error");
        });        
    },   
    getCashListMethod: function () {
      billService
        .getCashTypes()
        .then((response) => {
          this.cashtypeList.splice(0, this.cashtypeList.length);
          this.cashtypeList.push(...response);
          this.cashtype = this.cashtypeList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },     
    },
    watch: {
      cashtype(){
        if(this.cashtype.unitAmount!=undefined){
          this.exRate=this.cashtype.unitAmount;
        }
      },
      noteDto(){
      this.noteremark=this.noteDto.remark;
    },
        invPaymentDtl:function(){
        this.invPaymentHistoryListMethod();
        },
        cash(){
      this.iscash=true;
      this.bal = (Number(this.cash)*Number(this.exRate));
    },
    exRate(){
      this.bal = (Number(this.cash)*Number(this.exRate));       
    },
      },
      components: {}
    };
    </script>
    <style scoped>
       .login-full {
      height: 90vh;
    }
    .v-data-table td,
    .v-data-table th {
      padding: 0 5px !important;
    }
    </style>